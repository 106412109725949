import { React } from 'react'
import { Pagination } from 'react-bootstrap';

const PaginacaoLinks = ({ currentPage, next, prev, totalPages, linkPage, onClickPage }) => {

    console.log(currentPage)
    return (
        <>
        <div className='d-flex justify-content-center'>
        <Pagination className='d-flex'>
                {
                    Array.from({ length: totalPages }).map((it, index) => (
                        
                        <Pagination.Item key={index} 
                        active={(currentPage) === (index + 1)}
                        onClick={()=>onClickPage(index + 1)}
                        
                        >{(index+1)}</Pagination.Item>
                    ))
                }
            </Pagination>

        </div>
       


        </>
    )
}
export default PaginacaoLinks;