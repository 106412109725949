import react, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { AnimaisService } from '../../services/api/Animais/AnimaisService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import ModalSimNao from '../../components/ModalSimNao';

const AnimaisClientesList = ({ idCliente, setShowFormCad, setIdAnimal, showFormCad }) => {

    const [listAnimais, setListAnimais] = useState([]);
    const [idAnimalDelete, setIdAnimalDelete] = useState(null)
    const [showModalSN, setShowModalSN] = useState(false)

    const fetchAnimais = async (idCliente) => {

        const data = await AnimaisService.getAllByClienteId(idCliente);
        console.log(data.data)
        if (data.success) {
            setListAnimais(data.data)
        }

    }

    useEffect(() => {


        if (!showFormCad) {
            fetchAnimais(idCliente)
        }

    }, [showFormCad])

    const handleEditAnimal = (idAnimal) => {
        setIdAnimal(idAnimal)
        setShowFormCad(true)
    }



    const hanndleCloseDelete = () => {
        setShowModalSN(false)
    }

    const handleDelete = (idAnimal) => {
        setIdAnimalDelete(idAnimal)
        setShowModalSN(true)
    }

    const handleConfirmDelete = async () => {

        console.log(idAnimalDelete)
        const data = await AnimaisService.deleteById(idAnimalDelete)
        setShowModalSN(false)
        setIdAnimalDelete(null)
        fetchAnimais(idCliente)
    }



    return (
        <>

            <div>


                {(listAnimais.length > 0) ? (
                    <div>
                        <Table striped bordered responsive>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Idade</th>
                                    <th>Espécie</th>
                                    <th>Raça</th>
                                    <th>Porte</th>
                                    <th>Observações</th>
                                
                                    <th></th>
                                </tr>

                            </thead>
                            <tbody>
                                {(listAnimais.map((item) => (

                                    <tr>
                                        <td>{item.nome}</td>
                                        <td>{item?.idade}</td>
                                        <td>{item?.especies?.nome}</td>                                     
                                        <td>{item?.racas?.nome}</td>
                                        <td>{item?.portes?.nome}</td>
                                        <td>{item?.observacao}</td>
                                        <td>
                                            <Button size='sm' variant="primary" onClick={() => handleEditAnimal(item.id)} className="mr-10"><FontAwesomeIcon icon={faPenToSquare} /></Button>
                                            <Button size='sm' variant="danger" onClick={() => handleDelete(item.id)}><FontAwesomeIcon icon={faTrash} /></Button>

                                        </td>
                                    </tr>



                                )))}
                            </tbody>

                        </Table>

                        <ModalSimNao show={showModalSN} handleClose={hanndleCloseDelete} handleConfirm={handleConfirmDelete}>Tem certeza que deseja excluir este animal?</ModalSimNao>

                    </div>
                ) :
                    (
                        <div className='alert alert-warning text-center'>Sem animais registrados</div>
                    )
                }


            </div>

        </>
    )

}

export default AnimaisClientesList